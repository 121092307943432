@font-face{
  font-family: "MinisterStd";
  src: url("./fonts/MinisterStd-Bold.otf");
}
@font-face{
  font-family: "MaisonNeue";
  src: url("./fonts/MaisonNeue-Book.otf");
}

/*Header Styles*/
.MuiAvatar-colorDefault{
    background-color:#1C83DC !important;
}
.MuiAppBar-colorPrimary{
  background-color:#ffffff !important;
}

/*Form Styles*/
.MuiInput-underline:after, .MuiInput-underline:before{
  border:none !important;
}
.LineSheet-main-1 .ProductColorRow-product_details_square-38 .MuiInputBase-input{
  padding:0 !important;
  font-size:10px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border-radius: 0 !important;
}
